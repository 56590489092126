const isIBMInstance = true;
const isCohesityInstance = false;
const isSimpleSizer = false;

export const environment = {
  production: true,
  http: {
    baseUrl: '',
  },
  headerString: 'WEB SIZING TOOL',
  headerStringForSimpleSizer : 'SIMPLE-SIZER-PROD',
  disable_console: true,
  enableDataHawk: !isIBMInstance,
  isArchiveToTapeEnabled: !isIBMInstance,
  isSmartFilesSizerEnabled: !isIBMInstance,
  isCCSEnabled: !isIBMInstance,
  isFortKnoxVaultingEnabled: !isIBMInstance,
  isBundleLicensingEnabled: isCohesityInstance && !isIBMInstance,
  isIBMInstance: isIBMInstance,
  isCohesityInstance: isCohesityInstance,
  enableMetadataSizing: true,
  enableFKCAv2: false,
  enableHeterogeneousClusters: true,
  enableCustomDataReduction: true,
  enableClusterWideLimit: false,
  isSimpleSizer,
};
